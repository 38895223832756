html {
  --dark-green: rgb(19, 130, 117);
}

.chat-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: sans-serif;
    width: 90%;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden;
    border: 2px solid var(--dark-green);
  }

  .header {
    background-color: var(--dark-green);
    color: white;
    width: 100%;
    text-align: center;
    
  }
  .messages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 80%;
    overflow-y: auto;
    padding: 10px;
    margin-bottom: 10px;
    /*  */
    overflow-y: scroll;
    /* height: calc(100vh - 600px); */
    height: calc(100vh - 200px);

  }
  
  .message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
 
  .user {
    font-weight: bold;
    margin-right: 10px;
    color: #333;
    width: 200px;
    text-align: right;
  }


  .new-message-form {
    display: flex;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .new-message-input {
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    color: #333;
    padding: 10px;
    border-radius: 5px 0 0 5px;
  }
  
  .send-button {
    border: none;
    outline: none;
    background: var(--dark-green);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }


  .gif-picker-container {
    position: fixed;
    right: 2rem;
    bottom: 7rem;
  }